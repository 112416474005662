import { Policy } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getPolicies = (state: State) => state.policies;

export const getPolicyType = (state: State) => state.policyType;

export const getPolicyText = (state: State) => state.policyText;

export const getPolicyDetails = (state: State) => state.policyDetails;

export const selectPoliciesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('policies');

export const selectAllPoliciesItems: MemoizedSelector<
  object,
  Policy[]
> = createSelector(selectPoliciesState, getPolicies);

export const selectPoliciesError: MemoizedSelector<
  object,
  any
> = createSelector(selectPoliciesState, getError);

export const selectPoliciesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectPoliciesState, getIsLoading);

export const selectIsSaving = createSelector(
  selectPoliciesState,
  (state) => state.isSaving,
);

export const selectPolicyTypes: MemoizedSelector<object, any> = createSelector(
  selectPoliciesState,
  getPolicyType,
);

export const selectPolicyDetails: MemoizedSelector<
  object,
  any
> = createSelector(selectPoliciesState, getPolicyDetails);

export const selectPolicyText: MemoizedSelector<object, any> = createSelector(
  selectPoliciesState,
  getPolicyText,
);
