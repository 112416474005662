import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        policyDetails: action.payload.items,
      };
    }
    case ActionTypes.LOAD_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        policies: action.payload.items,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isSaving: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        policyType: action.payload.items,
      };
    }
    case ActionTypes.LOAD_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_TYPE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_TEXT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        policyText: action.payload.items,
      };
    }
    case ActionTypes.LOAD_TEXT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_TEXT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      const policies = state.policies.filter(
        (policy) => policy.policy_id !== action.payload.policyId,
      );
      return {
        ...state,
        policies,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isSaving: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
