import {
  CreatePolicyRequest,
  IPolicyText,
  IPolicyType,
  Policy,
  UpdatePolicyRequest,
} from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Policies] Load Request',
  LOAD_FAILURE = '[Policies] Load Failure',
  LOAD_SUCCESS = '[Policies] Load Success',

  LOAD_DETAILS_REQUEST = '[Policies] Load Details Request',
  LOAD_DETAILS_FAILURE = '[Policies] Load Details Failure',
  LOAD_DETAILS_SUCCESS = '[Policies] Load Details Success',

  LOAD_TYPE_REQUEST = '[Policies] Load Type Request',
  LOAD_TYPE_FAILURE = '[Policies] Load Type Failure',
  LOAD_TYPE_SUCCESS = '[Policies] Load Type Success',

  LOAD_TEXT_REQUEST = '[Policies] Load TEXT Request',
  LOAD_TEXT_FAILURE = '[Policies] Load TEXT Failure',
  LOAD_TEXT_SUCCESS = '[Policies] Load TEXT Success',

  CREATE_REQUEST = '[Policies] Create Request',
  CREATE_FAILURE = '[Policies] Create Failure',
  CREATE_SUCCESS = '[Policies] Create Success',

  DELETE_REQUEST = '[Policies] Delete Request',
  DELETE_FAILURE = '[Policies] Delete Failure',
  DELETE_SUCCESS = '[Policies] Delete Success',

  UPDATE_REQUEST = '[Policies] Update Request',
  UPDATE_FAILURE = '[Policies] Update Failure',
  UPDATE_SUCCESS = '[Policies] Update Success',

  OPEN_WARNINGS_MODAL = '[Policies] Open Warnings Modal',

  RESET_STATE = '[Policies] Reset State',
}

export class OpenWarningsModal implements Action {
  readonly type = ActionTypes.OPEN_WARNINGS_MODAL;
  constructor(
    public payload: {
      policyId: number;
      properties: number[];
      warnings: any[];
    },
  ) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Policy[] }) {}
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(public payload: { policyId: number }) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { items: Policy }) {}
}
export class LoadTypeRequestAction implements Action {
  readonly type = ActionTypes.LOAD_TYPE_REQUEST;
}

export class LoadTypeFailureAction implements Action {
  readonly type = ActionTypes.LOAD_TYPE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadTypeSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TYPE_SUCCESS;
  constructor(public payload: { items: IPolicyType[] }) {}
}
export class LoadTextRequestAction implements Action {
  readonly type = ActionTypes.LOAD_TEXT_REQUEST;
}

export class LoadTextFailureAction implements Action {
  readonly type = ActionTypes.LOAD_TEXT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadTextSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TEXT_SUCCESS;
  constructor(public payload: { items: IPolicyText }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: CreatePolicyRequest }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: {
      policyId: number;
      properties: number[];
      force_operation?: 1 | 0;
    },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { policyId: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;

  constructor(
    public payload: {
      policyId: number;
      request: UpdatePolicyRequest;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadDetailsRequestAction
  | LoadDetailsFailureAction
  | LoadDetailsSuccessAction
  | LoadTypeRequestAction
  | LoadTypeFailureAction
  | LoadTypeSuccessAction
  | LoadTextRequestAction
  | LoadTextFailureAction
  | LoadTextSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ResetSuccessAction
  | OpenWarningsModal;
