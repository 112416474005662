import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { GetPoliciesResponse } from '@app/models';
import { PoliciesService } from '@app/services';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';

import * as featureActions from './actions';

@Injectable()
export class PoliciesStoreEffects {
  constructor(
    private dataService: PoliciesService,
    private actions$: Actions,
    private warningModalService: WarningConfirmModalService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    private _router: Router,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { propertiesIds } = action.payload;
        return this.dataService.load(propertiesIds).pipe(
          map(
            ({ data }: GetPoliciesResponse) =>
              new featureActions.LoadSuccessAction({
                items: data.map((policy) => ({
                  ...policy,
                  id: policy.policy_id,
                })),
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadDetailsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAILS_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) => {
        const { policyId } = action.payload;
        return this.dataService.loadSinglePolicy(policyId).pipe(
          map(
            ({ data }: GetPoliciesResponse) =>
              new featureActions.LoadDetailsSuccessAction({
                items: data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadTypeRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadTypeRequestAction>(
        featureActions.ActionTypes.LOAD_TYPE_REQUEST,
      ),
      switchMap((action: featureActions.LoadTypeRequestAction) => {
        return this.dataService.loadSelectGeneralPolicyType().pipe(
          map(
            ({ data }: GetPoliciesResponse) =>
              new featureActions.LoadTypeSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadTypeFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadTextRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadTextRequestAction>(
        featureActions.ActionTypes.LOAD_TYPE_REQUEST,
      ),
      switchMap((action: featureActions.LoadTextRequestAction) => {
        return this.dataService.loadGeneralPolicyText().pipe(
          map(
            ({ data }: GetPoliciesResponse) =>
              new featureActions.LoadTextSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadTextFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { request } = action.payload;
        return this.dataService.create(request).pipe(
          mergeMap((response: IResponseSuccess) => {
            this._router.navigate(['/configuration/cancellation-policies']);
            this._notifications.push({
              title: this._translate.instant('done'),
              content: this._translate.instant('notifications.create_success', {
                param: this._translate.instant('policy'),
              }),
              type: 'success',
            });
            return [new featureActions.CreateSuccessAction()];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  openWarningsModalEffect$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.OpenWarningsModal>(
          featureActions.ActionTypes.OPEN_WARNINGS_MODAL,
        ),
        map((action: featureActions.OpenWarningsModal) => {
          const { policyId, properties, warnings } = action.payload;
          const deleteActionRequest = new featureActions.DeleteRequestAction({
            policyId,
            properties,
            force_operation: 1,
          });
          const deleteActionFailure = new featureActions.DeleteFailureAction({
            error: null,
          });
          this.warningModalService.open({
            message: warnings.toString(),
            action: deleteActionRequest,
            discardAction: deleteActionFailure,
          });
        }),
      ),
    { dispatch: false },
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteRequestAction) => {
        const { policyId, properties, force_operation } = action.payload;
        return this.dataService
          .delete(policyId, properties, force_operation)
          .pipe(
            mergeMap((response: IResponseSuccess) => {
              const {
                meta: { confirm_required, warnings },
              } = response;

              if (confirm_required) {
                return [
                  new featureActions.OpenWarningsModal({
                    policyId,
                    properties,
                    warnings,
                  }),
                ];
              }
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('policy'),
                  },
                ),
                type: 'success',
              });
              return [
                new featureActions.DeleteSuccessAction({
                  policyId,
                }),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(({ payload }: featureActions.UpdateRequestAction) => {
        const { request, policyId } = payload;
        return this.dataService.update(policyId, request).pipe(
          mergeMap(() => {
            this._notifications.push({
              title: this._translate.instant('done'),
              content: this._translate.instant('notifications.update_success', {
                param: this._translate.instant('policy'),
              }),
              type: 'success',
            });
            return [new featureActions.UpdateSuccessAction()];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
