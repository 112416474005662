import { IPolicyText, IPolicyType, Policy } from '@app/models';

export interface State {
  isLoading?: boolean;
  isSaving?: boolean;
  error?: any;
  policyType: IPolicyType[];
  policyText: IPolicyText;
  policyDetails: Policy;
  policies: Policy[];
}

export const initialState: State = {
  isLoading: false,
  isSaving: false,
  error: null,
  policyType: null,
  policyText: null,
  policyDetails: null,
  policies: [],
};
